import Layout from "../../components/layout/Layout";

const terms = `
            [ 약관 ]
            
            제 1 장 총 칙

            제 1조 <목적>
            이 약관은 (주)브레디스헬스케어(이하 "회사"는)의 Digital ELISA 연구분석서비스 웹사이트 (www.service.bredis.co.kr) 에서 제공하는 모든 인터넷서비스의 이용조건 및 절차에 관한 사항을 규정함으로 고객의 권익보호를 목적으로 합니다.

            제 2조 <용어의 정의>
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            1) "이용자"란 회사의 홈페이지에 접속하여 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
            2) "회원"이라 함은 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사의 정보를 지속적으로 제공받으며,회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
            3) "이용계약'이라 함은 서비스 이용과 관련하여 회사와 이용자 간에 체결하는 계약을 말합니다.
            4) "해지"라 함은 회사 또는 회원이 이용계약을 해약하는 것을 말합니다.

            제 3조 <약관의 효력과 변경>
            회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지 아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
            1) 이 약관은 이용자에게 공시함으로써 효력이 발생합니다.
            2) 회사는 사정 변경의 경우와 영업상 중요 사유가 있을 때 약관을 변경할 수 있으며, 변경된 약관은 전항과 같은 방법으로 효력을 발생합니다.

            제 4조 <약관 외 준칙>
            1) 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 서비스별 안내라 합니다)와 함께 적용합니다.
            2) 이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우에는 그 규정에 따릅니다.

            제 2 장 서비스 이용 계약

            제 5조 <이용계약의 성립>
            이용계약은 이용자의 이용신청에 대한 회사의 승낙과 일부 이용자의 약관 내용에 대한 동의로 성립됩니다.

            제 6조 <이용신청>
            1) 이용신청은 서비스의 주문 및 의뢰 화면에서 이용자가 회사에서 요구하는 주문 양식에 개인의 정보를 기록하여 신청할 수 있습니다.
            2) 회원가입은 반드시 실명으로만 가입할 수 있으며 회사는 실명확인조치를 할 수 있습니다. 또한 실명으로 등록하지 않은 사용자는 일체의 권리를 주장할 수 없습니다.

            제 7조 <이용신청의 승낙>
            1) 회원이 신청서의 모든 사항을 정확히 기재하여 이용신청을 하였을 경우에 특별한 사정이 없는 한 서비스 이용신청을 승낙합니다.
            2) 다음 각 호에 해당하는 경우에는 이용 신청에 대한 승낙을 하지 않을 수 있습니다.
            - 본인의 실명으로 신청하지 않았을 때
            - 타인의 명의를 사용하여 신청하였을 때
            - 이용신청의 내용을 허위로 기재한 경우
            - 사회의 안녕 질서 또는 미풍양속을 저해할 목적으로 신청하였을 때
            - 기타 회사가 정한 이용신청 요건에 미비 되었을 때

            제 8조 <계약사항의 변경>
            회원은 이용신청시 기재한 사항이 변경되었을 경우에는 수정하여야 하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게 있습니다.

            제 3 장 서비스 이용

            제 9조 <게시물 관리 및 삭제>
            회사는 효율적인 서비스 운영을 위하여 회원의 메모리 공간, 메시지크기, 보관일수 등을 제한할 수 있으며 등록하는 내용이 다음 각 호에 해당하는 경우에는 사전 통지없이 삭제할 수 있습니다.
            - 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
            - 공공질서 및 미풍양속에 위반되는 내용인 경우
            - 범죄적 행위에 결부된다고 인정되는 내용인 경우
            - 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
            - 회원이 회사의 홈페이지와 게시판에 음란물을 게재하거나 음란 사이트를 링크하는 경우
            - 기타 관계법령에 위반된다고 판단되는 경우

            제 10조 <서비스 이용 책임>
            회원은 서비스를 이용하여 해킹, 음란사이트 링크, 상용S/W 불법배포 등의 행위를 하여서는 아니되며, 이를 위반으로 인해 발생한 영업활동의 결과 및 손실, 관계기관에 의한 법적 조치 등에 관하여는 회사는 책임을 지지 않습니다.

            제 11조 <서비스 제공의 중지>
            회사는 다음 각 호에 해당하는 경우에 서비스 제공을 중지할 수 있습니다.
            - 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
            - 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우
            - 시스템 점검이 필요한 경우
            - 기타 불가항력적 사유가 있는 경우

            제 3 장 의 무

            제 12조 회사의 의무
            1) 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.
            2) 회사는 회원에게 계속적이고 안정적인 서비스 제공을 위해 노력할 의무가 있습니다.
            3) 회사는 계속적이고 안정적인 서비스의 제공을 위하여 설비에 장애가 생기거나 멸실된 때에는 부득이한 사유가 없는 한 지체없이 이를 수리 또는 복구합니다.
            4) 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서 적절한 절차를 거쳐 처리하며, 처리시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야 합니다.
            5) 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정되는 경우 이를 즉시 처리하여야 합니다.
            6) 회사는 개인정보 및 결제정보의 보안을 위해 노력하며, 이를 위해 담당자를 선정하여 관리하여야 합니다.
            7) 회사는 서비스 제공과 관련해서 알고 있는 회원의 신상 정보를 철저히 보안 유지하며, 양질의 서비스를 운영하거나 개선하는 데에만 사용하고, 이외의 다른 목적으로 타 기관 및 개인에게 양도하지 않습니다.
            8) 이용자가 구매한 제품의 사용 설명서와 다르게 사용하여 이용자 또는 제3자에게 피해를 입힌 경우, 회사는 책임지지 않습니다.
            9) 회원의 개인정보를 타인에게 유출시켜 발생하는 회사의 재산상 손해는 회원이 부담합니다.

            제 13조 회원의 의무
            1) 이용자는 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.
            2) ID(전자우편 주소) 및 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.
            3) 회원은 자신의 ID가 도용당하거나 제 3자에 의해 부정하게 사용된 경우, 반드시 회사에 그 사실을 통보해야 하며 회사의 안내가 있는 경우에는 그에 따라야 합니다.
            4) 회원은 회사의 사전 승낙없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우, 회원은 회사에 대해 손해배상의무를 지며, 회사는 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.
            5) 회원은 개인정보 변경 시 즉시 회사에 통보하거나 입력정보를 변경하여야 하며 이의 지체로 인한 손해는 회원에게 있습니다.
            6) 회원은 서비스의 일부로 보내지는 회사의 전자우편을 받는 것에 동의합니다.
            7) 회원은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 회사는 회원의 서비스 이용제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.
            - 회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위
            - 회사의 운영진, 직원 또는 관계자를 사칭하는 행위
            - 회사로부터 특별한 권리를 부여받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
            - 인터넷서비스에 위해를 가하거나 고의로 방해하는 행위
            - 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위
            - 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
            - 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
            - 다른 이용자를 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위
            - 사실관계를 왜곡하는 정보제공 행위 등 인터넷서비스에 부적절하다고 판단하는 행위
            - 본 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위
            - 범죄와 결부된다고 객관적으로 판단되는 행위를 포함한 기타 관계법령에 위배되는 행위

            제 4 장 계약 해지 및 이용 제한

            제 14조 <계약 변경 및 해지>
            회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 회사 담당자에게 인터넷을 통하여 해지신청을 하여야 하며, 회사에서는 본인 여부를 확인 후 조치합니다.

            제 15조 <서비스 이용제한>
            1) 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
            2) 회사는 시스템 등의 보수점검, 교체, 시스템의 고장, 통신의 두절, 기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
            3) 회사는 "2"항의 사유로 서비스 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제 3자가 입은 손해에 대하여는 배상하지 아니합니다.
            4) 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스 이용에 대하여 별도 공지 없이 서비스 이용의 일시정지, 초기화, 이용계약 해지 등을 불량이용자 처리규정에 따라 취할 수 있습니다.

            제 16조 <저작권의 귀속 및 이용제한>
            1) 웹사이트가 작성한 저작물에 대한 저작권 기타 지적 재산권은 웹사이트에 귀속합니다.
            2) 이용자는 웹사이트를 이용함으로써 얻은 정보를 웹사이트의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.

            제 17조 <분쟁해결>
            1) 웹사이트와 이용자는 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 노력을 합니다.
            2) 웹사이트는 이용자로부터 제출되는 불만사항 및 의견의 신속한 처리를 위해 노력합니다. 다만 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해 줍니다.
            3) 웹사이트와 이용자간에 발생한 분쟁은 전자거래 기본법 제 28조 및 동시행령 제 15조에 의하여 설치된 전자거래 분쟁 조정 위원회의 조정에 따를 수 있습니다.

            제 18조 <재판권 및 준거법>
            웹사이트와 이용자간에 발생한 분쟁에 대하여는 대한민국법을 적용하며, 분쟁이 소송으로 제기될 경우 민사소송법 상의 관할을 가지는 대한민국의 법원에 제기합니다.


            공고일자: 2023년 10월 11일
            시행일자: 2023년 10월 18일

`


function ServiceTerms () {
    return <Layout menuName="이용약관">
        <textarea value={terms} rows="25" className="resize-none"></textarea>
    </Layout>


}
export default ServiceTerms